export class CompanyInfo {
    companyID?: number;
    companyName: string = '';
    dba: string = '';
    einNumber: string = '';
    logoLink: string = 'truckincentral.com/Company/Logo/1'; // truckincentral.com/Company/Logo/1
    dotNumber: string = '';
    dotPin: string = '';
    regClearingHouse: boolean = false;
    drugTestEnrollment: boolean = false;
    backgroundCheck: boolean = false;

    constructor(source?: Partial<CompanyInfo>) {
        Object.assign(this, source);
    }
}

export class CompanyInfoSet {
    companyName: string = '';
    dba: string = '';
    einNumber: string = '';
    dotNumber: string = '';
    dotPin: string = '';
    regClearingHouse: boolean = false;
    drugTestEnrollment: boolean = false;
    backgroundCheck: boolean = false;
}


// {
//     CompanyID: number,
//     CompanyName: string,
//     DBA: string,
//     EinNumber: string,
//     DotNumber: string,
//     RegClearingHouse: boolean,
//     DrugTestEnrollment: boolean,
//     BackgroundCheck: boolean,
//     LogoLink: string
//   }
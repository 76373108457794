import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiResponseAny } from 'src/app/core/models/api-response.model';
import { CarrierAccount } from 'src/app/core/models/carrier-accounts.model';
import {
    CompanyInfo,
    CompanyInfoSet,
} from 'src/app/core/models/company-info.model';
import { tcRequireCompany } from 'src/app/core/interceptors/auth.interceptor';
import { FileService } from './file.service';
import { lastValueFrom } from 'rxjs';

const APIURL = `${environment.apiUrl}/cmp/`;

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    requestOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'json',
        }),
        responseType: 'json' as 'json',
    };

    constructor(private http: HttpClient, private fileService: FileService) {}

    async addCompany(
        id: number,
        ownerUserId: number,
        dotNumber: string
    ): Promise<ApiResponseAny> {
        return await lastValueFrom(this.http
            .post<ApiResponseAny>(
                APIURL + 'Company',
                {
                    id,
                    ownerUserId,
                    dotNumber,
                },
                { ...this.requestOptions, context: tcRequireCompany(false) }
            ));
    }

    //#region Company Information Setup

    //Update the Company Information
    public async updateCompany(
        companyID: number,
        companyInfo: Partial<CompanyInfoSet>
    ): Promise<ApiResponseAny> {
        return await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${environment.apiUrl}/cmp/Company/${companyID}`,
                companyInfo,
                { ...this.requestOptions, context: tcRequireCompany(false) }
            ));
    }

    //Update the Company Logo
    public async setCompanyLogo(
        companyID: number,
        file: File
    ): Promise<ApiResponseAny> {
        const formData = new FormData();
        formData.append('Logo', file);
        return await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${environment.apiUrl}/cmp/Company/${companyID}/Logo`,
                formData,
                { responseType: 'json' }
            ));
    }

    public async getCompanyLogo(companyID: number, token: any): Promise<any> {
        let result = await lastValueFrom(this.http
            .get<ApiResponseAny>(this.getCompanyLogoLink(companyID, token)));
        if (result.success) {
            return result.data;
        }
    }

    public getCompanyLogoLink(companyID: number, token: any): string {
        return `${environment.apiUrl}/cmp/Company/${companyID}/Logo/${token}`;
    }

    public async getCompanyInfo(companyID: number): Promise<CompanyInfo> {
        let result = await lastValueFrom(this.http
            .get<ApiResponseAny>(
                `${environment.apiUrl}/cmp/Company/${companyID}`,
                this.requestOptions
            ));
        if (result.success) {
            return new CompanyInfo({
                companyID: result.data.CompanyID,
                companyName: result.data.companyName,
                dba: result.data.DBA,
                einNumber: result.data.einNumber,
                logoLink: result.data.logoLink,
                dotNumber: result.data.dotNumber,
                dotPin: result.data.dotPin,
                regClearingHouse: result.data.regClearingHouse,
                drugTestEnrollment: result.data.drugTestEnrollment,
                backgroundCheck: result.data.backgroundCheck,
            });
        } else {
            return new CompanyInfo({});
        }
    }

    public async carrierFiles(
        companyID: number,
        docType: string,
        file: File
    ): Promise<ApiResponseAny> {
        const formData = new FormData();
        formData.append('DocumentType', docType);
        formData.append('Document', file);
        let documentId = await this.fileService.saveFile(file);
        return await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${environment.apiUrl}/cmp/Company/${companyID}/Document/${documentId.id}`,
                formData,
                { responseType: 'json' }
            ));
    }

    public async isCarrierFiles(
        companyID: number,
        docType: string
    ): Promise<boolean> {
        let result = await lastValueFrom(this.http
            .get<ApiResponseAny>(
                `${environment.apiUrl}/cmp/Company/Document/Exists/${companyID}/${docType}`,
                { ...this.requestOptions, context: tcRequireCompany(false) }
            ));
        if (result.success) {
            return result.data.status;
        } else {
            return false;
        }
    }

    public async permitFiles(
        companyID: number,
        docType: string,
        file: File
    ): Promise<ApiResponseAny> {
        const formData = new FormData();
        formData.append('DocumentType', docType);
        formData.append('Document', file);
        return await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${environment.apiUrl}/cmp/Company/Document/${companyID}`,
                formData,
                { responseType: 'json', context: tcRequireCompany(false) }
            ));
    }

    public async isPermitFiles(
        companyID: number,
        docType: string
    ): Promise<boolean> {
        let result = await lastValueFrom(this.http
            .get<ApiResponseAny>(
                `${environment.apiUrl}/Company/Document/Exists/${companyID}/${docType}`,
                { ...this.requestOptions, context: tcRequireCompany(false) }
            ));
        if (result.success) {
            return result.data.status;
        } else {
            return false;
        }
    }

    //Carrier Accounts
    public async carrierAccount(
        companyID: number,
        carrierAccount: CarrierAccount
    ): Promise<ApiResponseAny> {
        return await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${environment.apiUrl}/cmp/Company/${companyID}`,
                carrierAccount,
                { ...this.requestOptions, context: tcRequireCompany(false) }
            ));
    }

    //Delete File
    public async deleteFile(
        companyID: number,
        docType: string
    ): Promise<ApiResponseAny> {
        return await lastValueFrom(this.http
            .delete<ApiResponseAny>(
                `${environment.apiUrl}/cmp/Company/${companyID}/Document/${docType}`,
                this.requestOptions
            ));
    }
    //#endregion
}
